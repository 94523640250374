// Dont forget to sort these FF alphabetically
const FEATURE_FLAG = {
  CE_12_OPTIMIZE_NFT_CREATE_INVOICE_LOADING: 'CE-12_OPTIMIZE_NFT_CREATE_INVOICE_LOADING',
  KC_7484_WHT_OPTIONS: 'KC-7484_WHT_OPTIONS',
  KC_7528_ENABLE_CHECK_ALL_INVOICEABLE_DO: 'KC-7528_ENABLE_CHECK_ALL_INVOICEABLE_DO',
  KC_7545_RESTRICT_DO_SELECTION_BY_SCHEME: 'KC-7545_RESTRICT_DO_SELECTION_BY_SCHEME',
  KC_NFT_QUERY_IMPROVEMENT: 'KC_NFT_QUERY_IMPROVEMENT',
  KC_VAT_CALCULATION_TYPE: 'KC_VAT_CALCULATION_TYPE',
  NFT_EMATERAI: 'nft_ematerai',
  SUP_172_TABLE_INVOICE_ADOPTION_PERFORMANCE: 'SUP-172_table-invoice-adoption-performance',
  SUP_557_THE_LORRY_NFT_WHITE_LABEL: 'SUP-557_THE_LORRY_NFT_WHITE_LABEL',
  SUP_83_PERFORMANCE_TOOLBAR: 'SUP-83_performance-toolbar',
  SUP_THE_LORRY_NFT_MASKING: 'SUP_THE_LORRY_NFT_MASKING',
};

export default FEATURE_FLAG;
