import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/remote-config';

const config = {
  apiKey: window.FIREBASE_API_KEY,
  projectId: window.FIREBASE_PROJECT_ID,
  appId: window.FIREBASE_APP_ID,
  messagingSenderId: window.FIREBASE_MESSAGING_SENDER_ID,
};

const firebaseApp = firebase.initializeApp(config);
const messaging = firebase.messaging.isSupported() ? firebaseApp.messaging() : null;

const remoteConfig = firebase.remoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = window.ENVIRONMENT === 'PRD' ? (30 * 60 * 1000) : 0;

export {
  config,
  firebaseApp,
  messaging,
  remoteConfig,
};
