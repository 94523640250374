import React, { Suspense, lazy, useContext, useEffect } from 'react';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash-es';
import { useFlagsStatus, useFlag } from '@unleash/proxy-client-react';
import { LoadingSpinner, PageSuspenseFallback } from '@kargotech/tms-ui/components';

import {
  AVAILABLE_NFT_MODULES,
  COMPANY_VERIFICATION_LEVEL,
  USER_ACCESS_TYPE,
} from '~/Configurations/constants';
import { PrivateRoute, AuthRoute, BasicRoute } from '~/Components/Route';
import { ProfileContext } from '~/Contexts/ProfileProvider';
import { NftModulesContext } from '~/Contexts/NftModulesProvider';
import { ACCESS_PRIVILEGE } from '~/Configurations/accessPrevillegeMap';
import { getTrackerGeneralProps, PATH_TO_COMPONENT_MAPPING, TRACKER_NAMES } from '~/Utilities/trackerUtilities';
import { trackerEvent } from '~/Services/tracker';
import ShipmentRoutes from './ShipmentRoutes';
import AnalyticsRoutes from './AnalyticsRoutes';
import CompanyRoutes from './CompanyRoutes';
import FinanceRoutes from './FinanceRoutes';
import FaqDialog from '~/Pages/Bidding/FaqDialog';
import TncDialog from '~/Pages/Bidding/TncDialog';
import PaymentRoutes from './PaymentRoutes';
// will be used this banner in next sprint
import ContractDOExpireReminderBanner from '~/Modules/Company/Components/Banners/ContractDOExpireReminderBanner';
import FEATURE_FLAG from '../constants/featureFlag';
import useResponsiveUtils from '~/Hooks/useResponsiveUtils';
import useTheLorryIntegration from '~/Hooks/useTheLorryIntegration';

/* eslint-disable max-len */
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ '~/Layouts/MainLayout'));
const MinimalLayout = lazy(() => import(/* webpackChunkName: "MinimalLayout" */ '~/Layouts/MinimalLayout'));

const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ '~/Pages/Auth/Login'));
const RegisterPage = lazy(() => import(/* webpackChunkName: "RegisterPage" */ '~/Pages/Auth/Register'));
const RegisterDetailsPage = lazy(() => import(/* webpackChunkName: "RegisterDetailsPage" */ '~/Pages/Auth/Register/RegisterDetails'));
const OtpVerification = lazy(() => import(/* webpackChunkName: "OtpVerification" */ '~/Pages/Auth/OtpVerification'));
const ChooseCompanyPage = lazy(() => import(/* webpackChunkName: "ChooseCompanyPage" */ '~/Pages/Auth/ChooseCompanyPage'));

const PersonalAccount = lazy(() => import(/* webpackChunkName: "PersonalAccount" */ '~/Pages/PersonalAccount'));

const SwiftPaymentProjectDetailPublicPage = lazy(() => import(/* webpackChunkName: "SwiftPaymentProjectDetailsPage" */ '~/Modules/Payment/SwiftPayment/Pages/SwiftPaymentDetailPage/SwiftPaymentProjectDetailPublicPage/SwiftPaymentProjectDetailPublicPage'));
const CustomerListPage = lazy(() => import(/* webpackChunkName: "CustomerListPage" */ '~/Pages/Customers/CustomerListPage'));
const CustomerDetailPage = lazy(() => import(/* webpackChunkName: "CustomerDetailPage" */ '~/Pages/Customers/CustomerDetailPage'));
const WarehouseListPage = lazy(() => import(/* webpackChunkName: "WarehouseListPage" */ '~/Pages/Customers/WarehouseListPage'));
const WarehouseDetailPage = lazy(() => import(/* webpackChunkName: "WarehouseDetailPage" */ '~/Pages/Customers/WarehouseDetailPage'));

const ContainerDetailPage = lazy(() => import(/* webpackChunkName: "ContainerDetailPage" */ '~/Pages/Container/ContainerDetailPage'));

const VehicleTrackingPage = lazy(() => import(/* webpackChunkName: "VehicleTrackingPage" */ '~/Pages/Vehicle/VehicleTrackingPage'));
const VehicleAvailablityPage = lazy(() => import(/* webpackChunkName: "VehicleTrackingPage" */ '~/Pages/Vehicle/VehicleAvailability/VehicleAvailability'));

const WelcomePage = lazy(() => import(/* webpackChunkName: "WelcomePage" */ '~/Pages/WelcomePage'));

const BiddingActive = lazy(() => import(/* webpackChunkName: "BiddingActive" */ '~/Pages/Bidding/Active'));
const BiddingAvailable = lazy(() => import(/* webpackChunkName: "BiddingAvailable" */ '~/Pages/Bidding/Available'));
const BiddingHitory = lazy(() => import(/* webpackChunkName: "BiddingHitory" */ '~/Pages/Bidding/History'));
const BiddingDoDetailsOld = lazy(() => import(/* webpackChunkName: "BiddingDoDetailsOld" */ '~/Pages/Bidding/DoDetailsOld'));
const BiddingConfirmation = lazy(() => import(/* webpackChunkName: "BiddingConfirmation" */ '~/Pages/Bidding/Confirmation/Confirmation'));

const BiddingDoDetailsProvider = lazy(() => import(/* webpackChunkName: "BiddingDoDetailsProvider" */ '~/Pages/Bidding/BiddingDoDetails/Contexts/BiddingDoDetailProvider'));
const BiddingDoDetails = lazy(() => import(/* webpackChunkName: "BiddingDoDetails" */ '~/Pages/Bidding/BiddingDoDetails/Tabs/BiddingDoDetails'));
const BiddingDoFees = lazy(() => import(/* webpackChunkName: "BiddingDoFees" */ '~/Pages/Bidding/BiddingDoDetails/Tabs/BiddingDoFees'));

const PBIDriverVerificationBanner = lazy(() => import(/* webpackChunkName: "PBIDriverVerificationBanner " */ '~/Modules/Company/Components/Banners/PBIDriverVerificationBanner'));
const FreeTrialBanner = lazy(() => import(/* webpackChunkName: "FreeTrialBanner " */ '~/Modules/Company/Components/Banners/FreeTrialBanner'));

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '~/Pages/App/NotFound'));
const PerformancePage = lazy(() => import(/* webpackChunkName: "PerformanceRoutes" */'./PerformanceRoutes'));
const RedirectPage = lazy(() => import(/* webpackChunkName: "RedirectPage" */ '~/Modules/Misc/Pages/RedirectPage'));

const IS_DANTMS = window.DOMAIN === 'dantms.com';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function Routes() {
  const { t } = useTranslation();
  const location = useLocation();
  const { flagsReady } = useFlagsStatus();
  const BTMS_DO_MP = useFlag('TC-5650_BTMS-DO-MP');
  const REMOVE_SIGNUP = useFlag('TC-6169_REMOVE-SIGNUP');
  const BTMS_DRIVER_REGISTRATION = useFlag('TC-5658_BTMS-DRIVER-REGISTRATION');
  const FREE_TRIAL = useFlag('TC-6138_FREE-TRIAL');
  const DO_QUOTA_MONITORING = useFlag('TC-6522_DO-QUOTA-MONITORING');
  const REMOVE_BIDDING_FLOW = useFlag('TC-7070_REMOVE-BIDDING-FLOW');
  const SUP_83_PERFORMANCE_TOOLBAR = useFlag(FEATURE_FLAG.SUP_83_PERFORMANCE_TOOLBAR);
  const { isMobileScreen } = useResponsiveUtils();
  const { isTheLorryTransporter } = useTheLorryIntegration();

  const {
    getSelectedCompany,
    isAuthorizedToAccess,
    profile,
  } = useContext(ProfileContext);
  const {
    [AVAILABLE_NFT_MODULES.ANALYTICS]: showAnalytics,
    [AVAILABLE_NFT_MODULES.BTMS_INTEGRATION]: showIncentiveTab,
    [AVAILABLE_NFT_MODULES.EARNING_REPORT]: showEarningsReport,
    [AVAILABLE_NFT_MODULES.FLEET_AVAILABILITY]: showFleetAvailability,
    [AVAILABLE_NFT_MODULES.FLEET_MONITORING]: showFleetMonitoring,
  } = useContext(NftModulesContext);

  const verificationStatus = get(profile, 'company.pbiTransporterVerification.currentActiveCompanyStatus');
  const HAS_BTMS_PARTNER = BTMS_DO_MP && verificationStatus !== COMPANY_VERIFICATION_LEVEL.CREATED;
  const HAS_SHIPPER_PARTNER = Boolean(get(profile, 'company.hasConsentedCustomer'));
  const nftFreeTrialInformation = get(profile, 'company.nftFreeTrialInformation');

  useEffect(() => {
    const { pathname: path } = location;
    const routeMappedObject = PATH_TO_COMPONENT_MAPPING.find(obj => path.includes(obj.path));
    const trackerGeneralProps = getTrackerGeneralProps(profile);

    if (!routeMappedObject) return;

    trackerEvent({
      action: TRACKER_NAMES.NAVIGATION.PAGE.VIEWED,
      category: 'general',
      userKsuid: get(trackerGeneralProps, 'user_ksuid'),
    });
  }, [location, profile]);

  const customerPageTabNavigation = [
    {
      path: '/customer/customer-list',
      title: isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
        ? t('common:shipper')
        : t('common:customer'),
    },
    {
      path: '/customer/warehouse-list',
      title: t('common:warehouse'),
    },
  ];

  const BiddingPageNavigation = [
    {
      path: '/bidding/available',
      title: t('common:bidding_available'),
    },
    {
      path: '/bidding/active',
      title: t('common:bidding_active'),
    },
    ...BTMS_DO_MP && !REMOVE_BIDDING_FLOW
      ? [{
        path: '/bidding/confirmation',
        title: t('common:need_confirmation'),
      }] : [],
    {
      path: '/bidding/history',
      title: t('common:history'),
    },
  ].filter(Boolean);

  const vehiclePageNavigation = [
    ...showFleetMonitoring ? [{
      path: '/vehicle/tracking',
      title: t('common:vehicle_tracking'),
    }] : [],
    ...showFleetAvailability ? [{
      path: '/vehicle/available',
      title: t('common:vehicle_availablity'),
    }] : [],
  ];

  const renderDefaultRootPath = () => {
    if (getSelectedCompany().accessType === USER_ACCESS_TYPE.FINANCE) {
      return <Redirect exact={true} from="/" to="/company/details" />;
    }

    if (isMobileScreen || isTheLorryTransporter) {
      return <Redirect exact={true} from="/" to="/payment" />;
    }

    return <Redirect exact={true} from="/" to="/shipment" />;
  };

  const isBiddingRestricted = getSelectedCompany().accessType && (!isAuthorizedToAccess(ACCESS_PRIVILEGE.BIDDING_READ)
    || !HAS_SHIPPER_PARTNER);

  return (
    <Suspense fallback={<PageSuspenseFallback />}>
      {flagsReady
        ? (
          <Switch>
            <BasicRoute
              exact={true}
              isCenteredLogo={true}
              path="/redirect"
              showLogo={true}
            >
              <MinimalLayout showLogo={true}>
                <RedirectPage />
              </MinimalLayout>
            </BasicRoute>
            <Route
              exact={true}
              path="/projects/folders/:linkCode"
              showLogo={false}
            >
              <SwiftPaymentProjectDetailPublicPage />
            </Route>
            <Route path="/auth">
              <MinimalLayout>
                <Suspense fallback={<PageSuspenseFallback />}>
                  <Switch>
                    <AuthRoute
                      exact={true}
                      path="/auth/login/:accessType?"
                      showLogo={true}
                      title={t('common:login')}
                    >
                      <LoginPage />
                    </AuthRoute>
                    {!REMOVE_SIGNUP
                      ? (
                        <AuthRoute
                          exact={true}
                          path="/auth/register"
                          showLogo={true}
                          title={t('common:register')}
                        >
                          <RegisterPage />
                        </AuthRoute>
                      )
                      : null}
                    <AuthRoute
                      exact={true}
                      path="/auth/register/details/:phoneNumber"
                      showLogo={false}
                      title={t('message:fill_account_details')}
                    >
                      <RegisterDetailsPage />
                    </AuthRoute>
                    <AuthRoute
                      exact={true}
                      path="/auth/verification/:phoneNumber"
                    >
                      <OtpVerification options="REGISTRATION" />
                    </AuthRoute>
                    <AuthRoute
                      exact={true}
                      path="/auth/forgot-password/:phoneNumber"
                    >
                      <OtpVerification options="RESET_PASSWORD" />
                    </AuthRoute>
                    <AuthRoute
                      exact={true}
                      path="/auth/choose-company"
                      showLogo={false}
                      title={t('common:choose_company')}
                    >
                      <ChooseCompanyPage />
                    </AuthRoute>
                    <Redirect to="/auth/login" />
                  </Switch>
                </Suspense>
              </MinimalLayout>
            </Route>

            <PrivateRoute path="/welcome" title="">
              <MinimalLayout>
                <WelcomePage />
              </MinimalLayout>
            </PrivateRoute>

            <PrivateRoute path="/">
              {BTMS_DRIVER_REGISTRATION && (FREE_TRIAL && !nftFreeTrialInformation) && (
                <PBIDriverVerificationBanner
                  profile={profile}
                />
              )}
              { FREE_TRIAL && nftFreeTrialInformation && (
                <FreeTrialBanner
                  profile={profile}
                />
              )}
              { DO_QUOTA_MONITORING && (
                <ContractDOExpireReminderBanner
                  profile={profile}
                />
              )}
              <MainLayout>
                <Suspense fallback={<PageSuspenseFallback />}>
                  <Switch>
                    <PrivateRoute path="/shipment">
                      <ShipmentRoutes />
                    </PrivateRoute>
                    {showAnalytics && (
                      <PrivateRoute path="/report">
                        <AnalyticsRoutes />
                      </PrivateRoute>
                    )}
                    <PrivateRoute path="/company">
                      <CompanyRoutes />
                    </PrivateRoute>
                    {SUP_83_PERFORMANCE_TOOLBAR && (
                    <PrivateRoute path="/performance">
                      <PerformancePage />
                    </PrivateRoute>
                    )}
                    <PrivateRoute
                      exact={true}
                      path="/personal-account"
                      title={t('common:personal_account')}
                    >
                      <PersonalAccount />
                    </PrivateRoute>
                    { (showEarningsReport || showIncentiveTab) && (
                      <PrivateRoute
                        path="/finance"
                      >
                        <FinanceRoutes />
                      </PrivateRoute>
                    )}
                    { !(IS_DANTMS || !isAuthorizedToAccess(ACCESS_PRIVILEGE.PAYMENT_PROJECT)) && (
                      <PrivateRoute
                        path="/payment"
                      >
                        <PaymentRoutes />
                      </PrivateRoute>
                    )}

                    {IS_DANTMS && (
                      <Redirect
                        from="/customer"
                        to="/404"
                      />
                    )}
                    {isAuthorizedToAccess(ACCESS_PRIVILEGE.CUSTOMERS_READ)
                      && (
                        <PrivateRoute
                          exact={true}
                          path="/customer/customer-list"
                          tabNavigation={customerPageTabNavigation}
                          title={isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
                            ? t('common:shipper_list')
                            : t('common:customer_list')}
                        >
                          <CustomerListPage />
                        </PrivateRoute>
                      )}

                    {isAuthorizedToAccess(ACCESS_PRIVILEGE.CUSTOMERS_READ) && (
                      <PrivateRoute
                        exact={true}
                        path="/customer/customer-details/:customerKsuid/:companyKsuid?"
                      >
                        <CustomerDetailPage />
                      </PrivateRoute>
                    )}

                    {isAuthorizedToAccess(ACCESS_PRIVILEGE.CUSTOMERS_READ) && (
                      <Redirect
                        exact={true}
                        from="/customer"
                        to="/customer/customer-list"
                      />
                    )}

                    {isAuthorizedToAccess(ACCESS_PRIVILEGE.WAREHOUSES_READ) && (
                      <PrivateRoute
                        exact={true}
                        path="/customer/warehouse-details/:warehouseKsuid"
                      >
                        <WarehouseDetailPage />
                      </PrivateRoute>
                    )}

                    {isAuthorizedToAccess(ACCESS_PRIVILEGE.WAREHOUSES_READ) && (
                      <PrivateRoute
                        exact={true}
                        path="/customer/warehouse-list"
                        tabNavigation={customerPageTabNavigation}
                        title={isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
                          ? t('common:shipper_list')
                          : t('common:customer_list')}
                      >
                        <WarehouseListPage />
                      </PrivateRoute>
                    )}

                    <PrivateRoute
                      exact={true}
                      path="/container/details/:containerID"
                    >
                      <ContainerDetailPage />
                    </PrivateRoute>
                    { showFleetMonitoring
                      ? [
                        <PrivateRoute
                          exact={true}
                          path="/vehicle/tracking"
                          tabNavigation={vehiclePageNavigation}
                          title={t('common:vehicle')}
                        >
                          <VehicleTrackingPage />
                        </PrivateRoute>,
                      ] : [] }
                    { showFleetAvailability
                      ? [
                        <PrivateRoute
                          exact={true}
                          path="/vehicle/available"
                          tabNavigation={vehiclePageNavigation}
                          title={t('common:vehicle')}
                        >
                          <VehicleAvailablityPage />
                        </PrivateRoute>,
                      ] : []}
                    { showFleetMonitoring
                      ? [<Redirect
                          exact={true}
                          from="/vehicle"
                          to="/vehicle/tracking"
                      />]
                      : []}
                    { (!showFleetMonitoring && showFleetAvailability)
                      ? [<Redirect
                          exact={true}
                          from="/vehicle"
                          to="/vehicle/available"
                      />]
                      : []}

                    {(isBiddingRestricted && !HAS_BTMS_PARTNER) && (
                      <Redirect
                        from="/bidding"
                        to="/404"
                      />
                    )}
                    {isBiddingRestricted && !HAS_BTMS_PARTNER
                      ? [] : [
                        <Redirect
                          exact={true}
                          from="/bidding"
                          to="/bidding/available"
                        />,
                        <PrivateRoute
                          exact={true}
                          path="/bidding/available"
                          tabNavigation={BiddingPageNavigation}
                          title={t('common:bidding')}
                        >
                          <BiddingAvailable />
                        </PrivateRoute>,
                        <PrivateRoute
                          exact={true}
                          path="/bidding/active"
                          tabNavigation={BiddingPageNavigation}
                          title={t('common:bidding')}
                        >
                          <BiddingActive />
                        </PrivateRoute>,
                        <PrivateRoute
                          exact={true}
                          path="/bidding/history"
                          tabNavigation={BiddingPageNavigation}
                          title={t('common:bidding')}
                        >
                          <BiddingHitory />
                        </PrivateRoute>,
                        ...(BTMS_DO_MP ? [
                          <PrivateRoute
                            exact={true}
                            path="/bidding/faq"
                            title={t('common:bidding')}
                          >
                            <FaqDialog />
                          </PrivateRoute>,
                          <PrivateRoute
                            exact={true}
                            path="/bidding/tnc"
                            title={t('common:bidding')}
                          >
                            <TncDialog />
                          </PrivateRoute>,
                          ...(!REMOVE_BIDDING_FLOW ? [
                            <PrivateRoute
                              path="/bidding/confirmation"
                              tabNavigation={BiddingPageNavigation}
                              title={t('common:bidding')}
                            >
                              <BiddingConfirmation />
                            </PrivateRoute>,
                          ] : []),
                        ] : [])]}

                    {BTMS_DO_MP ? ([
                      <PrivateRoute
                        exact={true}
                        path="/bidding/details/:status/:shipmentID"
                        title={t('common:bidding')}
                      >
                        <BiddingDoDetailsProvider>
                          <BiddingDoDetails />
                        </BiddingDoDetailsProvider>
                      </PrivateRoute>,
                      <PrivateRoute
                        exact={true}
                        path="/bidding/fees/:status/:shipmentID"
                        title={t('common:bidding')}
                      >
                        <BiddingDoDetailsProvider>
                          <BiddingDoFees />
                        </BiddingDoDetailsProvider>
                      </PrivateRoute>,
                    ]) : ([
                      <PrivateRoute
                        exact={true}
                        path="/bidding/details/:status/:shipmentID"
                      >
                        <BiddingDoDetailsOld />
                      </PrivateRoute>,
                    ])}

                    {renderDefaultRootPath()}
                    <Route component={NotFound} />
                  </Switch>
                </Suspense>
              </MainLayout>
            </PrivateRoute>

            <Route component={NotFound} />
          </Switch>
        )
        : <LoadingSpinner />}
    </Suspense>
  );
}
